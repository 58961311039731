<template>
  <div>
    <p class="font-medium mb-5 text-gray-600">
      Government issued ID.
    </p>

    <form
      class="flex flex-col flex-grow"
      @submit.prevent="submit"
    >
      <!-- <form-group
        v-model="form.data.document_type.value"
        :form="form"
        name="document_type"
        type="id-type"
        class="mb-16"
      >
        Identification type
      </form-group>

      <p class="font-medium mb-4 text-12 text-left">
        Upload “Selected ID Type”
      </p> -->

      <div class="flex flex-col flex-grow">
        <dropzone
          v-if="!isUploaded"
          v-model="form.data.file.value"
          accept=".jpg, .jpeg, .png"
          class="mb-2 mr-2 py-8 w-full"
        />

        <div
          v-if="getFirstError('file')"
          class="form-group-2-text mb-10 text-red-500"
        >
          {{ getFirstError("file") }}
        </div>

        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-3">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <div class="flex gap-4 mt-auto">
          <button
            :type="isUploaded ? 'button' : 'submit'"
            class="btn"
            :class="isUploaded ? 'btn-green' : 'btn-blue'"
            :disabled="form.loading || isUploaded"
          >
            <sm-loader v-if="form.loading" class="sm-loader-white" />
            <span v-else>{{ isUploaded ? 'Uploaded' : 'Upload' }}</span>
            <i class="inline-flex ml-2 text-xl">
              <ion-icon
                v-if="isUploaded"
                key="uploaded"
                name="checkmark-done-outline"
              />
              <ion-icon
                v-else
                key="not-uploaded"
                name="cloud-upload-outline"
              />
            </i>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'GovtIdVerification',
  props: {
    isUploaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'file', value: null },
        // { name: 'document_type' },
      ]),
    };
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    async submit() {
      if (!this.validateForm(this.form)) return false;

      const data = new FormData();

      Object.keys(this.form.data).forEach((key) => {
        data.append(key, this.form.data[key].value);
      });

      this.form.error = null;
      this.form.setLoading();

      await this.sendRequest('personal.onboarding.documents.govtId', {
        data,
        success: (response) => {
          const { data: { user, documents } } = response.data;
          this.updateUser({ ...user, documents })
            .then(() => {
              this.$emit('upload-govt-id');
            });
        },
        error: (error) => {
          const { response: { code, data: { errors } = {} } = {} } = error;
          this.form.error = code !== 422 ? error : null;
          this.mapFormErrors(this.form, errors);
        },
      });

      this.form.setLoading(false);
    },
  }
}
</script>
